/**
 * カートページに関するjs
 */
import * as dateText from './functions/date';
const numberFormat = new Intl.NumberFormat();

$(function() {
    let prices = null;
    if(location.pathname.includes('cart')) {
      $.ajax({
          type: 'GET',
          url: ad_url.url,
          data: {
              'action': 'get_price',
          }
      }).done(function(res) {
          prices = res
          console.log(res)
          for(let key in prices) {
              prices[key] = parseInt(prices[key]);
          }
          if(!prices.ISUM_fee) {
            prices.ISUM_fee = 0
          }
          if(!prices.Blu_ray_fee) {
            prices.Blu_ray_fee = 0
          }
          if(!prices.discount_share) {
            prices.discount_share = 0
          }
      }).fail(function(XMLHttpRequest, textStatus, error) {
          console.log(error);
          console.log(XMLHttpRequest.responseText);
      });
      $('.p-cart-products__item__delete-button').on('click', function() {
          //二重送信を防ぐ
          $(this).attr('disabled', true);
          const productId = $(this).val();
          //ajax送信
          $.ajax({
              type: "POST",
              url: ad_url.url,
              data: {
                  'action' : 'delete_product',
                  'product_id' : productId,
                  'csrf_token' : csrfToken.value
              },
              dataType: "json"
          }).done(function(res) { //成功
              console.log(res);
              location.reload(false)
          }).fail(function(XMLHttpRequest, textStatus, error) { //失敗
              console.log(error);
              console.log(XMLHttpRequest.responseText);
              $(this).attr('disabled', false);
          });
      });
      $('input[name="wedding_date"]').on('change', function() {
        $('#errorForWeddingDate').addClass('d-none');
        const dateStr = $(this).val();
        const weddingDate = new Date(dateStr);
        const now = new Date();
        now.setHours(9, 0, 0, 0)
        const timeDiff = weddingDate.getTime() - now.getTime();
        const dayDiff = timeDiff / (1000 * 60 * 60 * 24);
        const earlyDay = 70;
        const deadLine = 21;
        let message = '';
        if(dayDiff >= earlyDay) {
            message = `<早割適応条件><br>${dateText.tenWeeksAgo(dateStr, 'jp')}までにすべての映像素材をご提出いただきますと早割特典の対象となります。`;
            $('input[name="quick_option"]').prop('checked', false).prop('disabled', false);
            $('#dateForWebConfirmation').text('映像素材ご提出日より8日前後')
            $('#dateForShipping').text('映像素材ご提出日より2週間前後')
            $('#deadlineForSubmitCaution').text('上記の期限までにすべての映像素材をご提出いただけないと特急制作料5,500円（税込）が発生します。')
            $('#deadlineForSubmit').text(dateText.threeWeeksAgo(dateStr, 'jp'));
            prices.discount_early = 2000;
            prices.quick_production_fee = 0;
        } else if(dayDiff < deadLine) {
            message = '挙式日が迫っておりますので特急制作料が必要となります。';
            $('input[name="quick_option"]').prop('checked', true).prop('disabled', true);
            $('#deadlineForSubmit').text('お早めに映像素材をご用意ください')
            $('#dateForWebConfirmation').text('映像素材ご提出日より3日後')
            $('#dateForShipping').text('映像素材ご提出日より1週間前後')
            $('#deadlineForSubmitCaution').text('当日の予期せぬトラブルを防ぐため、挙式日の1週間前までに会場にて再生テストを行ってください。')
            prices.quick_production_fee = 5500;
            prices.discount_early = 0;
        } else {
            $('input[name="quick_option"]').prop('checked', false).prop('disabled', false);
            $('#dateForWebConfirmation').text('映像素材ご提出日より8日前後')
            $('#dateForShipping').text('映像素材ご提出日より2週間前後')
            $('#deadlineForSubmitCaution').text('上記の期限までにすべての映像素材をご提出いただけないと特急制作料5,500円（税込）が発生します。')
            $('#deadlineForSubmit').text(dateText.threeWeeksAgo(dateStr, 'jp'));
            message = '';
            prices.discount_early = 0;
            prices.quick_production_fee = 0;
        }
      
        prices = computePriceTotal(prices)
      
        $('#messageForDate').html(message);
        updatePriceText(prices)
      })
      $('input[name="quick_option"]').on('change', function() {
        if($(this).prop('checked')) {
          prices.quick_production_fee = 5500;
          $('#deadlineForSubmit').text('お早めに映像素材をご用意ください')
          $('#dateForWebConfirmation').text('映像素材ご提出日より3日後')
          $('#dateForShipping').text('映像素材ご提出日より1週間前後')
          $('#deadlineForSubmitCaution').text('当日の予期せぬトラブルを防ぐため、挙式日の1週間前までに会場にて再生テストを行ってください。')
          $('#messageForDate').html('');
        } else {
          prices.quick_production_fee = 0;
          const dateStr = $('input[name="wedding_date"]').val();
          const weddingDate = new Date(dateStr);
          const now = new Date();
          now.setHours(9, 0, 0, 0)
          const timeDiff = weddingDate.getTime() - now.getTime();
          const dayDiff = timeDiff / (1000 * 60 * 60 * 24);
          const earlyDay = 70;
          const deadLine = 21;
          let message = '';
          if(dayDiff >= earlyDay) {
              message = `<早割適応条件><br>${dateText.tenWeeksAgo(dateStr, 'jp')}までにすべての映像素材をご提出いただきますと早割特典の対象となります。`;
              $('input[name="quick_option"]').prop('checked', false).prop('disabled', false);
              $('#dateForWebConfirmation').text('映像素材ご提出日より8日前後')
              $('#dateForShipping').text('映像素材ご提出日より2週間前後')
              $('#deadlineForSubmitCaution').text('上記の期限までにすべての映像素材をご提出いただけないと特急制作料5,500円（税込）が発生します。')
              $('#deadlineForSubmit').text(dateText.threeWeeksAgo(dateStr, 'jp'));
              prices.discount_early = 2000;
              prices.quick_production_fee = 0;
          } else {
              $('input[name="quick_option"]').prop('checked', false).prop('disabled', false);
              $('#dateForWebConfirmation').text('映像素材ご提出日より8日前後')
              $('#dateForShipping').text('映像素材ご提出日より2週間前後')
              $('#deadlineForSubmitCaution').text('上記の期限までにすべての映像素材をご提出いただけないと特急制作料5,500円（税込）が発生します。')
              $('#deadlineForSubmit').text(dateText.threeWeeksAgo(dateStr, 'jp'));
              message = '';
              prices.discount_early = 0;
              prices.quick_production_fee = 0;
          }
          $('#messageForDate').html(message);
        }
        prices = computePriceTotal(prices)
        updatePriceText(prices)
      })
      if($('input[name="ISUM_option"]').prop('checked')) {
        $('#ISUMList').show('normal')
      }
      if($('input[name="Blu-ray_option"]').prop('checked')) {
        $('#Blu-rayMessage').show('normal')
      }
      $('input[name="ISUM_option"]').on('change', function() {
        $('#ISUMList').toggle('normal')
          if(!$(this).prop('checked')) {
            prices.ISUM_fee = 0
          } else {
            const ISUMValue = $('input[name="ISUM"]:checked').val()
            console.log(ISUMValue)
            if(ISUMValue == '1曲') {
              prices.ISUM_fee = 3300
            } else if(ISUMValue == '2曲') {
              prices.ISUM_fee = 6600
            } else if(ISUMValue == '3曲') {
              prices.ISUM_fee = 9900
            } else if(ISUMValue == '4曲') {
              prices.ISUM_fee = 13200
            } else if(ISUMValue == '5曲') {
              prices.ISUM_fee = 16500
            } else {
              return
            }
          }
          prices = computePriceTotal(prices)
          updatePriceText(prices)
      })
      $('input[name="ISUM"]').on('change', function() {
        const ISUMValue = $(this).val()
        if(ISUMValue == '1曲') {
          prices.ISUM_fee = 3300
        } else if(ISUMValue == '2曲') {
          prices.ISUM_fee = 6600
        } else if(ISUMValue == '3曲') {
          prices.ISUM_fee = 9900
        } else if(ISUMValue == '4曲') {
          prices.ISUM_fee = 13200
        } else if(ISUMValue == '5曲') {
          prices.ISUM_fee = 16500
        } else {
          return
        }
        
        prices = computePriceTotal(prices)
        updatePriceText(prices)
      })
      $('input[name="Blu-ray_option"]').on('change', function() {
        $('#Blu-rayMessage').toggle('normal')
        if($(this).prop('checked')) {
          prices.Blu_ray_fee = 2200
        } else {
          prices.Blu_ray_fee = 0
        }
        prices = computePriceTotal(prices)
        updatePriceText(prices)
      })
      $('select[name="payment"]').on('change', function() {
          const value = $(this).val();
          if(value == '代金引換') {
              prices.payment_fee = 260;
          } else {
              prices.payment_fee = 0;
          }
          
          prices = computePriceTotal(prices)
          updatePriceText(prices)
      })
      $('input[name="share_option"]').on('change', function() {
          if($(this).prop('checked')) {
            prices.discount_share = 2000
          } else {
            prices.discount_share = 0
          }
          
          prices = computePriceTotal(prices)
          updatePriceText(prices)
      })
      $('#submitCartButton').on('click', function() {
          $(this).attr('disabled', true)
          if($(this).css('color') == 'rgb(255, 255, 255)') {
            $(this).append('<span class="c-loader c-loader--white d-inline-block ml-3" style="font-size: 5px;">Loading...</span>')
          } else {
            $(this).append('<span class="c-loader d-inline-block ml-3" style="font-size: 5px;">Loading...</span>')
          }
          $('#errorForSubmit').text('');
          $('input[name="deadline_for_submit"]').val($('#deadlineForSubmit').text())
          $('input[name="date_for_web_confirmation"]').val($('#dateForWebConfirmation').text())
          $('input[name="date_for_shipping"]').val($('#dateForShipping').text())
          if(!$('input[name="wedding_date"]').val()) {
              $('#errorForWeddingDate').text('挙式日を入力してください').removeClass('d-none')
              $(this).attr('disabled', false);
              $(this).children('.c-loader').remove()
              $('#errorForSubmit').text('入力にエラーがあります');
              return;
          }
          const cartData = {
              'wedding_date': $('input[name="wedding_date"]').val(),
              'casedesign': $('input[name="casedesign"]:checked').val(),
              'payment': $('select[name="payment"]').val(),
              'discount_total': prices.discount_total,
              'discount_early': prices.discount_early,
              'discount_early_deadline': dateText.tenWeeksAgo($('input[name="wedding_date"]').val(), 'jp'),
              'discount_share': prices.discount_share,
              'additional_fee': prices.additional_fee,
              'ISUM_fee': prices.ISUM_fee,
              'Blu_ray_fee': prices.Blu_ray_fee,
              'quick_production_fee': prices.quick_production_fee,
              'payment_fee': prices.payment_fee,
              'shipping_fee': prices.shipping_fee,
              'price_total': prices.total,
              'deadline_for_submit': $('input[name="deadline_for_submit"]').val(),
              'date_for_web_confirmation': $('input[name="date_for_web_confirmation"]').val(),
              'date_for_shipping': $('input[name="date_for_shipping"]').val(),
          }
          $.ajax({
              type: 'POST',
              url: ad_url.url,
              data: {
                  'action' : 'store_cart_data',
                  'cart_data': cartData,
                  'csrf_token' : csrfToken.value
              },
          }).done(function(res) {
              console.log(res);
              window.location.href = res;
          }).fail(function(XMLHttpRequest, textStatus, error) {
              console.log(XMLHttpRequest)
              console.log(textStatus)
              console.log(error);
          })
      })
    }
})

function computeDiscountTotal(prices) {
    return prices.discount_set + prices.discount_early + prices.discount_share;
}
function computeAdditionalFee(prices) {
    return prices.quick_production_fee + prices.shipping_fee + prices.payment_fee + prices.ISUM_fee + prices.Blu_ray_fee;
}
function computePriceTotal(prices) {
    prices.discount_total = computeDiscountTotal(prices);
    prices.additional_fee = computeAdditionalFee(prices);
    prices.total = prices.product_total - prices.discount_total + prices.additional_fee;

    prices.shipping_fee = computeShippingFee(prices.total);
    prices.additional_fee = computeAdditionalFee(prices);
    prices.total =  prices.product_total - prices.discount_total + prices.additional_fee;
    console.log(prices)
    return prices
}
function computeShippingFee(total) {
    const trigger = 30000
    let shippingFee = 0
    if(total < trigger) {
        shippingFee = 800
    }
    return shippingFee;
}
function updatePriceText(prices) {
    $('#productPriceTotal').text(numberFormat.format(prices.product_total))
    $('#discountSet').text(numberFormat.format(-prices.discount_set))
    $('#discountEarly').text(numberFormat.format(-prices.discount_early))
    $('#discountShre').text(numberFormat.format(-prices.discount_share))
    $('#discountTotal').text(numberFormat.format(-prices.discount_total))
    $('#additionalFee').text(numberFormat.format(prices.additional_fee))
    $('#quickProductionFee').text(numberFormat.format(prices.quick_production_fee))
    $('#ISUMFee').text(numberFormat.format(prices.ISUM_fee))
    $('#BluRayFee').text(numberFormat.format(prices.Blu_ray_fee))
    $('#paymentFee').text(numberFormat.format(prices.payment_fee))
    $('#shippingFee').text(numberFormat.format(prices.shipping_fee))
    $('#priceTotal').text(numberFormat.format(prices.total))
}