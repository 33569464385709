$(function() {
  $('.p-post-card[data-postcard]').each(function(index, el) {
    $.ajax({
      type: 'POST',
      url: ad_url.url,
      data: {
          'action': 'fetch_ogp',
          'url': $(el).attr('data-postcard')
      },
      dataType: "json"
    }).done(function(ogpDataArray) {
      $(el).find('.c-loader').remove();
      if(!$(el).find('img').attr('src')) {
        $(el).find('img').attr('src', ogpDataArray.thumbnail[0])
      }
      if($(el).find('.p-post-card__info__name').text().length < 2) {
        $(el).find('.p-post-card__info__name').text(ogpDataArray.title[0])
      }
      if($(el).find('.p-post-card__info__description').text().length < 2) {
        $(el).find('.p-post-card__info__description').text(ogpDataArray.description[0])
      }
      $(el).find('.p-post-card__img-container').removeClass('d-none')
      $(el).find('.p-post-card__info').removeClass('d-none')
    }).fail(function(XMLHttpRequest, textStatus, error) {
      console.log(error);
      console.log(XMLHttpRequest.responseText);
    });
  })
})