$('#addItemButton').on('click', function() {
  const $thisButton = $(this);
  //二重送信を防ぐ
  $thisButton.attr('disabled', true);
  //loading
  if($thisButton.css('color') == 'rgb(255, 255, 255)') {
    $thisButton.append('<span class="c-loader c-loader--white d-inline-block ml-3" style="font-size: 5px;">Loading...</span>')
  } else {
    $thisButton.append('<span class="c-loader d-inline-block ml-3" style="font-size: 5px;">Loading...</span>')
  }
  //ajax送信
  $.ajax({
      type: "POST",
      url: ad_url.url,
      data: {
          'action' : 'add_product',
          'product' : product,
          'csrf_token' : csrfToken.value
      },
      dataType: "json"
  }).done(function(data) { //成功
    console.log(data)
      if(!data) {
          $('#message').text('すでに商品が追加されています。');
      } else {
          $('.c-modal').show().css('display', 'flex')
          const cartBadghNum = ($('#cartBadge').text()) ? parseInt($('#cartBadge').text()) : 0
          const cartBadgeText = (cartBadghNum + 1).toString()
          console.log(cartBadgeText)
          if(cartBadghNum == 0) {
            $('#cart').prepend('<div id="cartBadge" class="position-absolute top-0 left-0 u-bg--red-400 u-w-5 u-h-5 rounded-circle text-center text-white font-weight-bold" style="line-height: 1.3rem;">'+cartBadgeText+'</div>')
          } else {
            $('#cartBadge').text(cartBadgeText)
          }
      }
      $thisButton.attr('disabled', false);
      $thisButton.children('.c-loader').remove()
  }).fail(function(XMLHttpRequest, textStatus, error) { //失敗
      console.log(error);
      console.log(XMLHttpRequest.responseText);
  });
})