import "./style.scss";
import "bootstrap";
import Swiper from "./swiperjs/swiper-bundle.min.js";

const swiper = new Swiper('.swiper-container', {
  slidesPerView: "auto",
  spaceBetween: 10,
  pagination: {
    el: '.swiper-pagination',
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    576: {
      spaceBetween: 20,
    }
  }
});

import lightbox from './lightbox/lightbox.min.js';
lightbox.option({
  'resizeDuration': 200,
  'wrapAround': true,
  'disableScrolling': true,
  'positionFromTop': 150
})

import './js/main.js'
import './js/accordion'
import './js/add-product'
import './js/font-replace'
import './js/pagination'
import './js/flow-schedule'
import './js/contact-form'
import './js/ranking'
import './js/cart'
import './js/order'
import './js/confirmation-order'
import './js/tab-content'
import './js/scroll'
import './js/copy-button'
import './js/video'
import './js/post-card'
import './js/table-of-contents'
import './js/loading'
import './js/modal-custom'