import flatpickr from "flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja.js"
import * as dateText from './functions/date';
flatpickr(".input-flatpickr", {
    'locale' : Japanese,
});

$(function () {
    $('#scheduleForm').on('submit', function (e) {
        e.preventDefault();
        $('#errorForDeliveryDate').text('')
        const productionPlan = $('select[name="production_plan"]').val();
        const deliveryDateText = $('input[name="delivery_date"]').val()
        const normalPlan = 'normal'
        const expressPlan = 'express'

        if(!deliveryDateText) {
            $('#errorForDeliveryDate').text('入力してください。')
            return;
        }

        console.log(deliveryDateText);

        if(productionPlan == normalPlan) {
            $('#dateForMaterial').text(dateText.twoWeeksAgo(deliveryDateText) + 'まで').removeClass('invisible')
            $('#dateForConfirmation').text(dateText.sixDaysAgo(deliveryDateText) + '前後').removeClass('invisible')
            $('#dateForDelivery').text(dateText.threeDaysAgo(deliveryDateText) + '前後').removeClass('invisible')
        } else if(expressPlan) {
            $('#dateForMaterial').text(dateText.oneWeekAgo(deliveryDateText) + 'まで').removeClass('invisible')
            $('#dateForConfirmation').text(dateText.fiveDaysAgo(deliveryDateText) + '前後').removeClass('invisible')
            $('#dateForDelivery').text(dateText.threeDaysAgo(deliveryDateText) + '前後').removeClass('invisible')
        }
    })
    $('input[name="wedding_date_for_discount"]').on('change', function() {
        $('#earlyDiscountText').html(`<span class="u-text-xl u-text-red font-weight-bold">${dateText.tenWeeksAgo($(this).val(), 'jp')}</span>までに映像素材をご提出いただくと<span class="u-underline">2,000円割引</span>の対象になります。`)
    })
    
    
})