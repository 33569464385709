export function threeDaysAgo(dateStr, format = '/') {
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 3);
    let dateText = ''
    if(format == 'jp') {
        dateText = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
    }
    else {
        dateText = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    } 
    return dateText;
}
export function fourDaysAgo(dateStr, format = '/') {
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 4);
    let dateText = ''
    if(format == 'jp') {
        dateText = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
    }
    else {
        dateText = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    } 
    return dateText;
}
export function fiveDaysAgo(dateStr, format = '/') {
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 5);
    let dateText = ''
    if(format == 'jp') {
        dateText = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
    }
    else {
        dateText = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    } 
    return dateText;
}

export function sixDaysAgo(dateStr, format = '/') {
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 6);
    let dateText = ''
    if(format == 'jp') {
        dateText = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
    }
    else {
        dateText = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    } 
    return dateText;
}

export function eightDaysAgo(dateStr, format = '/') {
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 8);
    let dateText = ''
    if(format == 'jp') {
        dateText = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
    }
    else {
        dateText = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    } 
    return dateText;
}

export function oneWeekAgo(dateStr, format = '/') {
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 7);
    let dateText = ''
    if(format == 'jp') {
        dateText = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
    }
    else {
        dateText = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    } 
    return dateText;
}

export function twoWeeksAgo(dateStr, format = '/') {
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 7 * 2);
    let dateText = ''
    if(format == 'jp') {
        dateText = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
    }
    else {
        dateText = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    } 
    return dateText;
}

export function threeWeeksAgo(dateStr, format = '/') {
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 7 * 3);
    let dateText = ''
    if(format == 'jp') {
        dateText = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
    }
    else {
        dateText = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    } 
    return dateText;
}

export function fourWeeksAgo(dateStr, format = '/') {
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 7 * 4);
    let dateText = ''
    if(format == 'jp') {
        dateText = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
    }
    else {
        dateText = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    } 
    return dateText;
}

export function tenWeeksAgo(dateStr, format = '/') {
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 7 * 10);
    let dateText = ''
    if(format == 'jp') {
        dateText = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
    }
    else {
        dateText = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    } 
    return dateText;
}

export function oneMonthAgo(dateStr, format = '/') {
    const date = new Date(dateStr);
    date.setMonth(date.getMonth() - 1);
    let dateText = ''
    if(format == 'jp') {
        dateText = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
    }
    else {
        dateText = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    } 
    return dateText;
}

export function tenDaysLater(dateStr, format = '/') {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + 10);
    let dateText = ''
    if(format == 'jp') {
        dateText = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
    }
    else {
        dateText = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    } 
    return dateText;
}