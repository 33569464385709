$(function() {
    $('.menu-toggle-btn').on('click', function() {
        $('.p-main-navigation').toggleClass('is-active');
        $('.p-main-navigation__content').delay(200).fadeToggle();
    });

    const now = new Date();
    now.setDate(now.getDate() + 10);
    const dateText = `${now.getMonth() + 1}月${now.getDate()}日`
    $('#tenDaysLater').text(dateText)

    $('iframe').addClass('u-w-full')
    $('p:empty').remove();
    const ua = window.navigator.userAgent.toLowerCase();
    if(ua.indexOf("mac os x") === -1) {
      $('.fw-500').removeClass('fw-500')
    }
});

