$(function() {
    const paginationItems = $('.p-pagination__list__item')
    if(paginationItems) {
        paginationItems.each(function(index, item) {
            if($(item).children().hasClass('dots')) {
                $(item).addClass('p-pagination__list__item--border-none')
            }
            if($(item).children().hasClass('current')) {
                $(item).addClass('p-pagination__list__item--bg-main')
            }
        })
    }
})