$(function() {
    $('#profileForm').on('submit', function(e) {
        e.preventDefault();
        const $button = $('button[type="submit"]')
        
        //loading
        $button.attr('disabled', true);
        if($button.css('color') == 'rgb(255, 255, 255)') {
          $button.append('<span class="c-loader c-loader--white d-inline-block ml-3" style="font-size: 5px;">Loading...</span>')
        } else {
          $button.append('<span class="c-loader d-inline-block ml-3" style="font-size: 5px;">Loading...</span>')
        }

        $('#errorForSubmit').text('');
        var formData = new FormData(e.target);
        if($('input[name="privacy"]').prop('checked')) {
          formData.append('privacy', $('input[name="privacy"]').val())
        } else {
          formData.append('privacy', '')
        }
        var errors = validation(formData);
        $('.error').text('');
        if(Object.keys(errors).length !== 0) {
            for(var key in errors) {
                var strArray = key.split('_');
                
                for(var i = 0 ; i < strArray.length ; i++) {
                    if(i == 0) {
                        continue;
                    }
                    strArray[i] = strArray[i][0].toUpperCase() + strArray[i].slice(1);
                }
                var keyForId = strArray.join('') + 'Error';
                $(`#${keyForId}`).text(errors[key]);
            }
            $button.attr('disabled', false);
            $button.children('.c-loader').remove()
            $('#errorForSubmit').text('入力に誤りがあります。');
            return;
        }

        formData.append('action', 'store_user_data');
        $.ajax({
            type: "POST",
            url: ad_url.url,
            data: formData,
            processData: false,
            contentType: false,
        }).done(function(data) {
            console.log(data);
            window.location.href = data;
        }).fail(function(XMLHttpRequest, textStatus, error) {
            console.log(error);
            console.log(XMLHttpRequest.responseText);
            $('button[type="submit"]').attr('disabled', false);
        });
    });
});

function validation(formData) {
    var errors = {};
    var regex = null;
    for(var item of formData) {
        var key = item[0];
        var value = item[1];

        $el = $(`[name='${key}']`);
        if($el.hasClass('required') && !value) { // 必須項目のバリデーション
            errors[key] = 'この項目は入力必須です。';
        }
        else if($el.hasClass('number')) {
            regex = /^[0-9]+$/;
            if(!regex.test(value)) {
                errors[key] = '数値のみで入力してください。';
            }
        }
        else if($el.hasClass('katakana')) {
            regex = /^[ァ-ンヴー\s]*$/;
            if(!regex.test(value)) {
                errors[key] = 'カタカナで入力してください。';
            }
        }
        else if($el.hasClass('email')) {
            regex = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
            if(!regex.test(value)) {
                errors[key] = '無効なメールアドレスです。';
            }
        }
        else if($el.hasClass('email-confirmation') && formData.get('email') !== value) {
            errors[key] = 'メールアドレスが一致していません。';
        }
    }

    return errors;
}