$(function() {
    $('body').append('<a href="#" id="toTopButton" class="p-scroll-top-button"><i class="fas fa-chevron-up"></i></a>')
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 500) {
        $('#toTopButton').fadeIn();
      } else {
        $('#toTopButton').fadeOut();
      }
    });
    $('a[href^="#"]').on('click', function() {
        const href = $(this).attr("href");
        const target = $(href == "#" || href == "" ? 'html' : href);
        if(target.css('display') !== 'none') {
            const headerHeight = $('header').height();
            const position = target.offset().top - headerHeight;
            const speed = 500;
            $("html, body").animate({
                scrollTop: position
            }, speed, "swing");
        }
    })
})