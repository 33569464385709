$(function() {
  $('.c-video').each(function(index, el) {
    if(!$(el).find('c-video__playbutton')) return;
    const videoSrc = $(el).find('iframe').attr('src')
    const splitVideoSrc = videoSrc.split('/')
    const videoId = splitVideoSrc[splitVideoSrc.length - 1];
    
    if(videoSrc.includes('youtube')) {
      const thumbnailSrc = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
      $(el).find('.c-video__playbutton').css({'background': `url('${thumbnailSrc}') no-repeat`, 'background-size': 'cover'})
    } else if(videoSrc.includes('vimeo')) {
      $.ajax({
        type: 'GET',
        url: ad_url.url,
        data: {
            'action': 'fetch_vimeo_data',
            'video_id': videoId
        },
      }).done((res) => {
        const thumbnailSrc = res.thumbnail_url
        $(el).find('.c-video__playbutton').css({'background': `url('${thumbnailSrc}') no-repeat`, 'background-size': 'cover'})
      }).fail(function(XMLHttpRequest, textStatus, error) { //失敗
        console.log(error);
        console.log(XMLHttpRequest.responseText);
      });
    }
  })
  $('.c-video__playbutton').on('click', function() {
    const $button = $(this)
    $button.css('background', '#FFF');
    setTimeout(function() {
      $button.addClass('disabled')
    }, 800)
  })
})