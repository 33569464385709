/**
 * アコーディオンリストのアイコンの向きを変える（Bootstrap4での処理）
 */

$(function() {
    $('.collapse').on('show.bs.collapse', function() {
        const collapseId = $(this).attr('id')
        const $accordionButton = $('button[data-target="#'+collapseId+'"]');
        const $accordionIcon = $accordionButton.children('.c-accordion__button__icon')
        const reverseClass = 'c-accordion__button__icon--reverse';
        $accordionIcon.addClass(reverseClass);
    }).on('hide.bs.collapse', function() {
        const collapseId = $(this).attr('id')
        const $accordionButton = $('button[data-target="#'+collapseId+'"]');
        const $accordionIcon = $accordionButton.children('.c-accordion__button__icon')
        const reverseClass = 'c-accordion__button__icon--reverse';
        $accordionIcon.removeClass(reverseClass);
    })
})