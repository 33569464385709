$(function() {
    $('#submitConfirmationButton').on('click', function() {
        $(this).attr('disabled', true);

        $('body').prepend('<div class="c-loader-wrap" style="background: rgba(255, 255, 255, 0.8);"><div class="c-loader mx-auto">Loading</div></div>')
        $.ajax({
            type: "POST",
            url: ad_url.url,
            data: {
                'action' : 'store_confirmation_data',
                'csrf_token' : csrfToken.value
            },
            // dataType: "json"
        }).done(function(data) {
            console.log(data);
            window.location.href = data;
        }).fail(function(XMLHttpRequest, textStatus, error) {
            $('.c-loader-wrap').css('display', 'none')
            console.log(error);
            console.log(XMLHttpRequest.responseText);
            $(this).attr('disabled', false);
        });
    })
})