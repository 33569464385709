/**
 * 商品名が英語の場合、フォントを変える
 */

$(function() {
    let names = $('.c-box-content__info__name');
    let regex = /^[0-9a-zA-Z\s&_]*$/;
    names.each(function(index, name) {
        if(regex.test($(name).text())) {
            $(name).addClass('u-font-eng-script-product')
        }
    })
});