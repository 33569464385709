/**
 * 商品ランキングを表示する
 */
$(function() {
    $('.ranking-button').on('click', function() {
        let categorySlug = '';
        if($(this).attr('id') == 'rankingButtonProfile') {
            categorySlug = 'profile-movie';
            categoryName = 'プロフィールムービー';
            $('#rankingButtonProfile').removeClass('c-button--outline').addClass('c-button--active');
            $('#rankingButtonOpening').removeClass('c-button--active').addClass('c-button--outline');
            $('#rankingButtonEnding').removeClass('c-button--active').addClass('c-button--outline');
        } else if($(this).attr('id') == 'rankingButtonOpening') {
            categorySlug = 'opening-movie';
            categoryName = 'オープニングムービー';
            $('#rankingButtonProfile').removeClass('c-button--active').addClass('c-button--outline');
            $('#rankingButtonOpening').removeClass('c-button--outline').addClass('c-button--active');
            $('#rankingButtonEnding').removeClass('c-button--active').addClass('c-button--outline');
        } else if($(this).attr('id') == 'rankingButtonEnding') {
            categorySlug = 'ending-movie';
            categoryName = 'エンドロールムービー';
            $('#rankingButtonProfile').removeClass('c-button--active').addClass('c-button--outline');
            $('#rankingButtonOpening').removeClass('c-button--active').addClass('c-button--outline');
            $('#rankingButtonEnding').removeClass('c-button--outline').addClass('c-button--active');
        } else {
           return;
        }
        const rankingFormData = new FormData();
        rankingFormData.append('category_slug', categorySlug);
        rankingFormData.append('action', 'fetch_popular_products');
        $.ajax({
            type: 'POST',
            url: ad_url.url,
            data: rankingFormData,
            contentType: false,
            processData: false
        }).done(function(res) {
            const ua = window.navigator.userAgent.toLowerCase();
            let rankingHTML = '';
            for(var i = 0 ; i < res.posts.length ; i++) {
                let post = res.posts[i];
                let thumbnail = res.thumbnails[post.ID];
                let permalink = res.permalinks[post.ID];

                let regex = /^[0-9a-zA-Z\s&_]*$/;
                let fontClass = '';
                if(regex.test(post.post_title)) {
                    fontClass = 'u-font-eng-script-product';
                }
                if(ua.indexOf("mac os x") !== -1) {
                  fontClass += ' fw-500';
                }

                rankingHTML += '<div class="p-post-index swiper-slide mr-3">' +
                                    '<div class="p-post-index__post c-box-content">' +
                                        '<div class="p-post-index__post__img img-container">' +
                                            '<img src="'+thumbnail+'" width="350" height="197" alt="'+post.post_title+'">' +
                                        '</div>' +
                                        '<div class="p-post-index__post__info c-box-content__info">' +
                                            '<p class="p-post-index__post__info__category c-box-content__info__category">'+categoryName+'</p>' +
                                            '<p class="p-post-index__post__info__name c-box-content__info__name '+fontClass+'">'+post.post_title+'</p>' +
                                        '</div>' +
                                        '<a href="'+permalink+'" class="stretched-link"></a>' +
                                    '</div>' +
                                '</div>'
            }
            $('.p-ranking__content .swiper-wrapper').html(rankingHTML);
            
        }).fail(function(XMLHttpRequest, textStatus, error) {
            console.log(error);
            
        });
    });
});