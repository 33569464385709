$(function() {
    $('#contactForm').on('submit', function(e) {
        e.preventDefault();
        const $button = $('#contactFormButton')
        $button.attr('disabled', true);
        //loading
        $button.attr('disabled', true);
        if($button.css('color') == 'rgb(255, 255, 255)') {
          $button.append('<span class="c-loader c-loader--white d-inline-block ml-3" style="font-size: 5px;">Loading...</span>')
        } else {
          $button.append('<span class="c-loader d-inline-block ml-3" style="font-size: 5px;">Loading...</span>')
        }

        const contactFormData = new FormData(e.target);
        if($(this).attr('data-form') == 'company') {
          contactFormData.append('action', 'store_contact_form_company');
        } else {
          contactFormData.append('action', 'store_contact_form');
        }
        $.ajax({
            type: 'POST',
            url: ad_url.url,
            data: contactFormData,
            contentType: false,
            processData: false
        }).done(function(res) {
            console.log(res);
            if(res) {
              $('#contactFormMessage').text('送信しました。');
              $('#contactForm').find('input').each(function(index, el) {
                $(el).val('')
              })
              $('#contactForm').find('textarea').val("");
              $('.c-modal').show().css('display', 'flex')
            } else {
              $('#contactFormMessage').text('送信に失敗しました');
            }
            $button.children('.c-loader').remove()
            $('#contactFormMessage').removeClass('d-none');
        }).fail(function(XMLHttpRequest, textStatus, error) {
            console.log(error);
            $('#contactFormMessage').text('エラーがありました。お手数ですが再度送信してください。');
            $('#contactFormMessage').removeClass('d-none');
            $('#contactFormButton').attr('disabled', false);
            $button.children('.c-loader').remove()
        });
    });
})